$(document).ready(function() {
  const animateCSS = function (element, animation) {
    // We create a Promise and return it
    return new Promise((resolve, reject) => {
      const animationName = 'animate__'+animation;
      const node = document.querySelector(element);

      node.style.setProperty('--animate-duration', '0.5s');
      node.classList.remove('is-hidden');
      node.classList.add('animate__animated', animationName);

      // When the animation ends, we clean the classes and resolve the Promise
      function handleAnimationEnd() {
        node.classList.remove('animate__animated', animationName);
        resolve('Animation ended');
      }

      node.addEventListener('animationend', handleAnimationEnd, {once: true});
    });
  };

  // Show or hide body nav element depending on position on screen
  const checkAndActivateBodyNav = function() {
    let hT = $('.hero').offset().top,
        hH = $('.hero').outerHeight(),
        wH = $(window).height(),
        wS = $(this).scrollTop();
    if (wS > (hT+hH)){
      if ($('#body-nav').hasClass('is-hidden')) {
        // Fade in
        animateCSS('#body-nav', 'slideInDown');
      }
    } else {
      if (!$('#body-nav').hasClass('is-hidden')) {
        // Fade out
        animateCSS('#body-nav', 'slideOutUp').then((message) => {
          $('#body-nav').addClass('is-hidden');
        });
      }
    }
  };

  // On load and on scroll, check whether to activate body nav
  $(window).scroll(checkAndActivateBodyNav);
  checkAndActivateBodyNav();
});
